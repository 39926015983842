@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ol {
    list-style: decimal;
    margin-left: 2em;
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-internal-autofill-selected,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: #ffffff; */
  appearance: menulist-button;
  background-image: none !important;
  background-color: inherit !important;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 0 1000px #f8f8f8 inset !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  flex-wrap: wrap;
}
/* styles.css (or your preferred stylesheet) */
.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple-animation 0.4s linear;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
}

@keyframes ripple-animation {
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}

* {
  font-family: "Roboto", sans-serif;
}

.player-wrapper {
  position: relative;
  padding-top: 100%; /* 1:1 Aspect Ratio */
}

.text-stroke {
  -webkit-text-stroke: 2px white;
}

.input-container:focus-within label {
  transform: translate(0, 4px) scale(0.8);
  color: #909090;
}

/* width */
::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
}

.rdp-months {
  justify-content: center;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: orange;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.right-align-toolbar .ql-toolbar {
  display: flex;
  flex-direction: row-reverse;
}
.ql-tooltip {
  z-index: 99;
  top: 24px !important;
  left: 48px !important;
}
.ql-toolbar {
  width: fit-content;
  margin-left: auto;
}
.ql-toolbar.ql-snow {
  @apply shadow-[0px_4px_10px_0px_rgba(183,183,183,0.05),0px_4px_20px_0px_rgba(230,230,230,0.10)_inset];
  background: white;
  border: none !important;
  border-radius: 0 10px 0px 0px;
}
.ql-container.ql-snow {
  border: none;
}

.quill {
  border: 1px solid #ccc;
  border-radius: 10px;
  /* background: white; */
}

.ql-toolbar .ql-label {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #f3f3f3;
  /* border: 1px solid #ccc; */
  border-radius: 3px;
  cursor: default;
}

.ql-container {
  /* border: 1px solid #ccc; */
  border-radius: 0 0 10px 10px;
  background-color: #fafafa;
}
.ql-toolbar {
  /* border: 1px solid #ccc; */
  border-radius: 10px 10px 0 0;
  font-size: 14px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

video {
  border-radius: 1rem;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;

  cursor: pointer;
  border: 2px solid #725278;
  border-radius: 50%;
  background-color: transparent;
}

input[type="radio"]:checked {
  background: #725278;
  border: 2px solid #725278;
  box-shadow: inset 0.1px 0.1px 0px 2px #fff; /* Add box shadow to simulate a small dot */
}

/* Style for Chrome, Safari */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  color: #fff;

  border-radius: 50%;
  /* background: gold; */
  cursor: pointer;
  /* You might need to adjust this value */
}

.recharts-default-legend {
  text-align: initial !important;
  margin: auto !important;
}

table,
.divTable {
  table-layout: auto;
  /* border: 1px solid lightgray; */
  /* width: fit-content; */
}

.tr {
  /* display: flex; */
}

tr,
.tr {
  /* @apply border-y border-y-[#EAF0F3]; */
  /* width: fit-content; */
  height: 30px;
}

/* th,
.th,
td,
.td {
  @apply py-1 text-start text-[#767576] capitalize
  /* box-shadow: inset 0 0 0 1px lightgray; */
/* padding: 0.25rem; */
/*
} 
*/
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}
.rdrDay {
  border: none !important;
  color: transparent;
  width: 40px !important;
  height: 40px !important;
}

.rdrWeekDay {
  flex-basis: auto !important;
  width: 40px !important;
  height: 40px !important;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  outline: none !important;
}
.rdrDayHovered {
  border: none !important;
  color: transparent !important;
}
.rdp-day {
  margin: 0 6px !important;
  color: transparent !important;
}
.rdrDayStartPreview {
  border: none !important;
}
.rdrDayEndPreview {
  border: none !important;
}
.rdrDay {
  color: transparent !important;
  border: none !important;
}
.rdrDayHovered {
  border: none !important;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
.rdp {
  --rdp-border-color: transparent !important;
  --rdp-cell-size: 40px; /* Size of the day cells. */
  --rdp-caption-font-size: 16px; /* Font size for the caption labels. */
  --rdp-accent-color: #0000ff; /* Accent color for the background of selected days. */
  --rdp-background-color: #e7edff; /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #3003e1; /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #180270; /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff; /* Color of selected day text */
}

.rdrDays {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.DayPicker-Day {
  margin: 4px; /* Adjust the space as needed */
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* Add other styles here */
}

/* If you want to keep the days as squares but with spacing */
.DayPicker-Day {
  margin: 14px; /* Adjust the space as needed */
  /* Other styles here */
}

/* Styles for today and selected days as previously mentioned */
.today {
  background-color: #ff7f50; /* Example color for today */
  color: white;
}

.selected {
  background-color: #ffd700; /* Example color for selected days */
  color: white;
}

/* Ensure the width and height are set to maintain the size when adding margin */
.DayPicker-Day {
  width: 40px; /* Example size, adjust as needed */
  height: 40px; /* Example size, adjust as needed */
  /* other styles */
}

.rdrWeekDays {
  justify-content: center;
  align-items: center;
  color: #7e818c;
  text-transform: uppercase;
}

.rdrDays {
  justify-content: center;
  align-items: center;
}

/* CustomStyles.css */

/* ... additional styles */

.rdrDayNumber {
  font-size: 14px !important;
}

.rdrDay {
  border: none !important;
  color: transparent;
  width: 40px !important;
  height: 40px !important;
}

.rdrWeekDay {
  flex-basis: auto !important;
  width: 40px !important;
  height: 40px !important;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  outline: none !important;
}
.rdrDayHovered {
  border: none !important;
  color: transparent !important;
}
.rdp-day {
  margin: 0 6px !important;
  color: transparent !important;
}
.rdrDayStartPreview {
  border: none !important;
}
.rdrDayEndPreview {
  border: none !important;
}
.rdrDay {
  color: transparent !important;
  border: none !important;
}
.rdrDayHovered {
  border: none !important;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}

.rdrDays {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.DayPicker-Day {
  margin: 4px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.DayPicker-Day {
  margin: 14px;
}

.today {
  background-color: #ff7f50;
  color: white;
}

.selected {
  background-color: #ffd700;
  color: white;
}

.DayPicker-Day {
  width: 40px;
  height: 40px;
}

.rdrWeekDays {
  justify-content: center;
  align-items: center;
  color: #7e818c;
  text-transform: uppercase;
}

.rdrDays {
  justify-content: center;
  align-items: center;
}

.rdrCalendarWrapper {
  position: relative;
  /* box-shadow: 8px 3px 22px 10px #9696961c; */
  border-radius: 16px;
}
.rdrMonth {
  padding-top: 15px !important;
}
.rdrMonthPicker > select {
  position: absolute;
  left: 1.2rem;
  top: 1rem;
  font-size: 13px;
  color: #1f3161 !important;
  font-weight: 700 !important;
  pointer-events: none;
  background: transparent !important;
  opacity: 0.5;
}
.rdrYearPicker > select {
  position: absolute;
  left: 5.5rem;
  top: 1.05rem;
  font-size: 13px;
  color: #1f3161 !important;
  font-weight: 700 !important;
  background: transparent !important;
  pointer-events: none;
  opacity: 0.5;
}

.rdrMonthAndYearWrapper {
  position: relative;
}

.rdrMonthAndYearWrapper::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 18rem;
  height: 2px;
  background: #e4e5e7;
  transform: translateX(-50%);
}
.rdrNextPrevButton.rdrNextButton > i {
  display: none;
}
.rdrNextPrevButton.rdrPprevButton > i {
  display: none;
}
.rdrNextPrevButton.rdrNextButton {
  position: absolute;
  right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("/public/images/calendarRightIcon.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
}

.rdrNextPrevButton.rdrNextButton:hover {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("/public/images/calendarRightIcon.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
}

.rdrNextPrevButton.rdrPprevButton {
  position: absolute;
  right: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("/public/images/calendarLeftIcon.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
}
.rdrNextPrevButton.rdrPprevButton:hover {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("/public/images/calendarLeftIcon.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
}

/* custom CheckBox */
.fontnunito {
  font-family: "Nunito";
}

.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #f58125;
  box-shadow: -24px 0 #f2f2f2, 24px 0 #f2f2f2;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}
input:autofill {
  background: transparent; /* or any other */
}

@keyframes shadowPulse {
  33% {
    background: #f3f4f6;
    box-shadow: -24px 0 #ff3d00, 24px 0 #f3f4f6;
  }
  66% {
    background: #ff3d00;
    box-shadow: -24px 0 #f3f4f6, 24px 0 #f3f4f6;
  }
  100% {
    background: #f3f4f6;
    box-shadow: -24px 0 #f3f4f6, 24px 0 #ff3d00;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 5rem;
}
.lds-ellipsis div {
  position: absolute;
  top: 2.0833rem;
  width: 0.8333rem;
  height: 0.8333rem;
  border-radius: 50%;
  background: #f97316;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 0.5rem;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 0.5rem;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 2rem;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 3.5rem;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1.5rem, 0);
  }
}
